.ele-divider[data-v-742e790a] {
  position: relative;
  background-color: #dcdfe6;
  display: block;
  height: 1px;
  width: 100%;
  margin: 24px 0;
}
.ele-divider .el-divider__text[data-v-742e790a] {
    position: absolute;
    background-color: #fff;
    padding: 0 20px;
    color: #303133;
}
.ele-divider .in-center[data-v-742e790a] {
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
}
.ele-divider .in-left[data-v-742e790a] {
    left: 20px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.ele-divider .in-right[data-v-742e790a] {
    right: 20px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
