.ele-select-container[data-v-1755429b] {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.ele-select-container .ele-select-configable[data-v-1755429b] {
    width: calc(100% - 50px) !important;
}
.ele-select-container .ele-select-configable[data-v-1755429b] .el-input__inner {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      border-right: 0px;
}
.ele-select-container .ele-select-icon[data-v-1755429b] {
    width: 48px;
    height: 36px;
    text-align: center;
    border: 1px solid #dcdfe6;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    cursor: pointer;
}
[data-v-1755429b] .cus-formdata-dialog {
  height: 100%;
}
[data-v-1755429b] .cus-formdata-dialog .el-dialog__body {
    height: 100%;
}
.readonly-select[data-v-1755429b] .el-input__inner {
  background: #f5f7fa;
  border-color: #e4e7ed;
  cursor: pointer;
  color: #409eff;
}
.width100[data-v-1755429b] {
  width: 100%;
}
.multiple-select[data-v-1755429b] {
  padding: 0 15px;
  width: 100%;
  background: #f5f7fa;
  border-radius: 5px;
}
.multiple-tag[data-v-1755429b] {
  cursor: pointer;
  color: #409eff;
  margin-right: 5px;
  -webkit-box-shadow: 0 0 4px #dddbdb;
          box-shadow: 0 0 4px #dddbdb;
}
