
.ql-editor {
  height: 400px;
}
.edit_container .text_number_tips {
  float: right;
  margin-top: -30px;
  padding-right: 10px;
}
.warn_edit_container {
  border: solid 1px #f56c6c;
}
.warn_text_number_tips {
  color: #f56c6c;
}
