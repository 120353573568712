[data-v-48d59424] .ele-priview-dialog .el-dialog__header {
  text-align: center;
  font-size: var(--rootFontSize);
  border-bottom: 1px solid #ddd;
}
[data-v-48d59424] .ele-priview-dialog .ele-priview-content {
  height: 500px;
  width: 100%;
}
[data-v-48d59424] .ele-priview-dialog .ele-priview-content .ele-priview-image {
    width: 100%;
    height: 100%;
}
[data-v-48d59424] .ele-priview-dialog .ele-priview-content .ele-priview-image .image-slot {
      width: 100%;
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      background-color: #eee;
}
[data-v-48d59424] .ele-priview-dialog .ele-priview-content .ele-priview-image .image-slot i {
        font-size: 120px;
}
