.header[data-v-6a89c13f] {
  position: relative;
  height: 44px;
  line-height: 44px;
  width: 100%;
  letter-spacing: 2px;
  border-bottom: 1px solid #cacaca;
  text-indent: 10px;
  font-size: 20px;
  font-weight: 550;
  color: #0070c1;
}
.header .header-more[data-v-6a89c13f] {
    cursor: pointer;
    color: #aaa;
    position: absolute;
    right: 10px;
    top: 0;
}
.header .header-more[data-v-6a89c13f]:hover {
      color: #409eff;
}
.header .header-more span[data-v-6a89c13f] {
      font-size: 14px;
}
.header .header-more span i[data-v-6a89c13f] {
        margin-left: -12px;
}
[data-v-6a89c13f] .el-card__body {
  padding: 0;
}
