.ele-checkbox-container[data-v-0c4f6fbc] {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.ele-checkbox-container .ele-checkbox-configable[data-v-0c4f6fbc] {
    width: calc(100% - 50px) !important;
}
.ele-checkbox-container .ele-checkbox-icon[data-v-0c4f6fbc] {
    width: 48px;
    height: 36px;
    text-align: center;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    cursor: pointer;
}
.ele-checkbox-container .ele-checkbox-icon i[data-v-0c4f6fbc] {
      font-size: var(--rootFontSize);
}
[data-v-0c4f6fbc] .cus-formdata-dialog {
  height: 100%;
}
[data-v-0c4f6fbc] .cus-formdata-dialog .el-dialog__body {
    height: 100%;
}
