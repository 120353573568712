.header[data-v-682a397e] {
  height: 44px;
  line-height: 44px;
  width: 100%;
  letter-spacing: 2px;
  border-bottom: 1px solid #cacaca;
  text-indent: 10px;
  font-size: 20px;
  font-weight: 550;
  color: #0070c1;
  position: relative;
}
.header .header-more[data-v-682a397e] {
    cursor: pointer;
    color: #aaa;
    position: absolute;
    right: 10px;
    top: 0;
}
.header .header-more[data-v-682a397e]:hover {
      color: #409eff;
}
.header .header-more span[data-v-682a397e] {
      font-size: 14px;
}
.header .header-more span i[data-v-682a397e] {
        margin-left: -12px;
}
.list-con[data-v-682a397e] {
  margin-top: 20px;
  width: 100%;
  height: calc(100% - 44px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.list-con .item[data-v-682a397e] {
    margin-bottom: 10px;
    width: 25%;
    height: auto;
}
.list-con .item .item-title[data-v-682a397e] {
      width: 100%;
      text-align: center;
}
.list-con .item .img-con[data-v-682a397e] {
      cursor: pointer;
      width: 60px;
      height: 60px;
      margin: auto;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      padding: 8px;
      background-color: rgba(238, 238, 238, 0.509);
      border-radius: 4px;
      margin-bottom: 4px;
}
.list-con .item .img-con img[data-v-682a397e] {
        width: 100%;
        height: 100%;
}
[data-v-682a397e] .el-card__body {
  padding: 0;
}
