.ele-select-container[data-v-6620f5fd] {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.ele-select-container .ele-select-configable[data-v-6620f5fd] {
    width: calc(100% - 50px) !important;
}
.ele-select-container .ele-select-configable[data-v-6620f5fd] .el-input__inner {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      border-right: 0px;
}
.ele-select-container .ele-select-icon[data-v-6620f5fd] {
    width: 48px;
    height: 36px;
    text-align: center;
    border: 1px solid #dcdfe6;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    cursor: pointer;
}
.ele-select-container[data-v-6620f5fd] .cus-treeselect {
    line-height: 30px;
}
.ele-select-container[data-v-6620f5fd] .cus-treeselect .vue-treeselect__placeholder {
      line-height: 30px;
}
.ele-select-container[data-v-6620f5fd] .cus-treeselect .vue-treeselect__control {
      height: 30px !important;
      line-height: 30px;
}
.ele-select-container[data-v-6620f5fd] .cus-treeselect .vue-treeselect__control .vue-treeselect__value-container .vue-treeselect__single-value {
        line-height: 30px;
}
.ele-select-container[data-v-6620f5fd] .cus-treeselect .vue-treeselect__control .vue-treeselect__value-container .vue-treeselect__multi-value {
        height: 30px;
        line-height: 30px;
        margin-bottom: 0px;
}
.ele-select-container[data-v-6620f5fd] .cus-treeselect .vue-treeselect__control .vue-treeselect__value-container .vue-treeselect__multi-value .vue-treeselect__multi-value-item-container {
          height: 26px;
          padding-top: 2px;
}
.ele-select-container[data-v-6620f5fd] .cus-treeselect .vue-treeselect__control .vue-treeselect__value-container .vue-treeselect__multi-value .vue-treeselect__multi-value-item-container .vue-treeselect__multi-value-item {
            line-height: 20px;
}
.ele-select-container[data-v-6620f5fd] .cus-treeselect .vue-treeselect__control .vue-treeselect__value-container .vue-treeselect__multi-value .vue-treeselect__limit-tip {
          height: 26px;
          padding-top: 2px;
}
.ele-select-container[data-v-6620f5fd] .cus-treeselect .vue-treeselect__control .vue-treeselect__value-container .vue-treeselect__multi-value .vue-treeselect__limit-tip .vue-treeselect__limit-tip-text {
            line-height: 20px;
}
.ele-select-container[data-v-6620f5fd] .cus-treeselect .vue-treeselect__control .vue-treeselect__value-container .vue-treeselect__multi-value .vue-treeselect__input-container {
          height: 20px;
          line-height: 20px;
}
.ele-select-container[data-v-6620f5fd] .cus-treeselect .vue-treeselect__control .vue-treeselect__value-container .vue-treeselect__multi-value .vue-treeselect__input-container .vue-treeselect__input {
            vertical-align: middle;
}
[data-v-6620f5fd] .cus-formdata-dialog {
  height: 100%;
}
[data-v-6620f5fd] .cus-formdata-dialog .el-dialog__body {
    height: 100%;
}
