.img[data-v-7a391182] {
  display: block;
  width: 100%;
  height: 100%;
}
.sub-title[data-v-7a391182] {
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
}
[data-v-7a391182] .ele-priview-dialog .el-dialog__header {
  text-align: center;
  font-size: var(--rootFontSize);
  border-bottom: 1px solid #ddd;
}
[data-v-7a391182] .ele-priview-dialog .ele-priview-content {
  height: 500px;
  width: 100%;
}
[data-v-7a391182] .ele-priview-dialog .ele-priview-content .ele-priview-image {
    width: 100%;
    height: 100%;
}
[data-v-7a391182] .ele-priview-dialog .ele-priview-content .ele-priview-image .image-slot {
      width: 100%;
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      background-color: #eee;
}
[data-v-7a391182] .ele-priview-dialog .ele-priview-content .ele-priview-image .image-slot i {
        font-size: 120px;
}
[data-v-7a391182] .el-carousel__indicators--horizontal {
  height: 28px;
}
[data-v-7a391182] .el-carousel--horizontal {
  overflow-y: hidden;
}
