.el-col-border[data-v-87fce878] {
  border-right: 1px dashed black;
  text-align: center;
}
.header[data-v-87fce878] {
  position: relative;
  height: 44px;
  line-height: 44px;
  width: 100%;
  letter-spacing: 2px;
  border-bottom: 1px solid #cacaca;
  text-indent: 10px;
  font-size: 20px;
  font-weight: 550;
  color: #0070c1;
}
.header .header-more[data-v-87fce878] {
    cursor: pointer;
    color: #aaa;
    position: absolute;
    right: 10px;
    top: 0;
}
.header .header-more[data-v-87fce878]:hover {
      color: #409eff;
}
.header .header-more span[data-v-87fce878] {
      font-size: 14px;
}
.header .header-more span i[data-v-87fce878] {
        margin-left: -12px;
}
.list-con[data-v-87fce878] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-top: 10px;
  width: 100%;
  height: 100%;
  padding: 0 10px;
}
.list-con .list-text[data-v-87fce878] {
    cursor: pointer;
    white-space: nowrap;
}
.list-con .list-text[data-v-87fce878]:hover {
      color: #1f8cf7;
      text-decoration: underline;
}
[data-v-87fce878] .el-card__body {
  padding: 0;
}
