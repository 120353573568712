.node-content-textarea {
  min-height: 60px;
  line-height: 20px;
  font-size: var(--rootFontSize);
  position: relative;
  border: 1px solid #dcdfe6;
  min-height: 60px;
  border-radius: 5px;
  padding: 5px 15px;
}
.node-content-advise {
  padding-bottom: 35px;
}
.node-content-sign {
  position: absolute;
  right: 5px;
  bottom: 5px;
}
.node-content-sign > span + span {
    margin-left: 10px;
}
.node-grid > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 20px;
  min-height: 20px;
  position: relative;
}
.node-grid > div > *:nth-child(1) {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    padding-bottom: 40px;
}
.node-grid > div > *:nth-child(2) {
    position: absolute;
    bottom: 0px;
    right: 225px;
}
.node-grid > div > *:nth-child(3) {
    position: absolute;
    right: 0;
    bottom: 0;
}
.node-grid .node-grid-label {
  float: left;
  position: absolute;
  left: -55px;
}
.node-mutiple__row + .node-mutiple__row {
  margin-top: 10px;
}
.node-link-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
